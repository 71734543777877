<template>
  <div>
    <b-row
      class="padding--x py-2 d-flex justify-content-between align-items-center"
    >
      <b-col class="p-0"
        ><div class="cursor-pointer" @click.stop="$router.push(`/${orgId}`)">
          <img
            class="company-logo"
            :src="require(`@/assets/images/logo/career-lab.svg`)"
            alt="Career Lab"
          /></div
      ></b-col>
      <b-col class="d-flex justify-content-end pr-0">
        <unified-menu class="mr-50" v-if="isUserLoggedIn" />

        <div
          v-if="isUserLoggedIn"
          @click="$store.commit('modals/OPEN_EDITOR_POPUP', true)"
          class="cursor-pointer custom-btn d-none d-md-flex align-items-center justify-content-center"
        >
          <p class="m-0 custom-btn-text">My Profile</p>
        </div>

        <a
          v-else
          :href="`${constants.loginServiceUrl}/client/${constants.clientId}/oauth`"
          class="cursor-pointer custom-btn d-none d-md-flex align-items-center justify-content-center"
        >
          <p class="m-0 custom-btn-text">Login</p>
        </a>

        <div
          v-if="isUserLoggedIn"
          @click="$store.commit('modals/OPEN_EDITOR_POPUP', true)"
          class="cursor-pointer d-flex d-md-none align-items-center justify-content-center"
        >
          <feather-icon
            :color="appColor"
            :size="isDeviceSmall ? '27' : '32'"
            icon="UserIcon"
          />
        </div>

        <a
          v-else
          :href="`${constants.loginServiceUrl}/client/${constants.clientId}/oauth`"
          class="cursor-pointer d-flex d-md-none align-items-center justify-content-center"
        >
          <feather-icon
            :color="appColor"
            :size="isDeviceSmall ? '27' : '32'"
            icon="UserIcon"
          />
        </a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import constants from "@/constants";
import designMixin from "@/mixins/designMixin.js";
import { BRow, BCol } from "bootstrap-vue";
import UnifiedMenu from "./UnifiedMenu.vue";

export default {
  name: "Navbar",
  mixins: [designMixin],
  components: {
    BCol,
    BRow,
    UnifiedMenu,
  },
  data() {
    return {
      constants,
    };
  },
  computed: {
    currentTheme() {
      return this.$store.state.user.themeType;
    },
    isUserLoggedIn() {
      return this.$store.state.auth.isUserLoggedIn;
    },
    userData() {
      return this.$store.state.user.data;
    },
    orgId() {
      return this.$store.state.user.orgId;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
</style>

<style lang="scss" scoped>
.custom {
  &-btn {
    padding: 0.85em;
    border-radius: 5px;
    &-text {
      font-size: 1.1em;
      font-weight: 600;
      color: #fff;
    }
  }
}
</style>
