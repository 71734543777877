export default {
  data() {
    return {
      isDeviceSmall: false,
      appColor: "#F63D68",
    };
  },
  computed: {
    windowSize() {
      return this.$store.getters["app/currentBreakPoint"];
    },
    currentTheme() {
      return this.$store.state.user.themeType;
    },
  },
  watch: {
    windowSize(newVal) {
      this.checkDeviceSize(newVal);
    },
  },
  mounted() {
    this.checkDeviceSize(this.windowSize);
    this.setAppColor(this.appColor);
  },
  methods: {
    checkDeviceSize(val) {
      if (val === "sm" || val === "xs") {
        return (this.isDeviceSmall = true);
      }
    },
    setAppColor(color) {
      let domStyle = document.documentElement.style;
      domStyle.setProperty("--org-theme-color", color);
      domStyle.setProperty("--org-theme-color--low", color + "1f");
    },
  },
};
