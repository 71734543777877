module.exports = [
  {
    id: "resume-editor",
    title: "Resume Editor",
    shortTitle: "Editor",
    color: "#FFD600",
    shortDescription:
      "Infographic Resume Editor for Skill and Personality Profiling.",
    description:
      "Getboarded editor is a competency mapping tool for professionals and students that helps them showcase their skills and personality. This qualitative information is used the basis for other apps to be selected and utilized for their personal development.<br><br> The editor works, not just on the knowledge skills of a person, but also personality-based transferable skills in today’s ever-changing career and jobs landscape.<br><br> It can get directly integrated/embedded into an organization website and work to create talent boards as a SaaS service. Based on the talent profiles, the system can further predict learning needs and provides future career guidance for students and early career professionals.<br><br> App is customizable to add different designs, templates and colors to suit personality or organization brand.",
    icon: require("@/assets/images/icons/unified-menu/editor.svg"),
    strokeIcon: require("@/assets/images/icons/unified-menu/stroke-icons/editor.svg"),
    uni_color_icon: require("@/assets/images/icons/unified-menu/uni-color/editor.svg"),
    pricePerUser: "0",
    defaultCurreny: "eur",
    baseURL: "editor/about",
    baseURLDisabled: false,
    manager: "GetBoarded",
  },
  {
    id: "resume-parser",
    title: "Resume Parser",
    shortTitle: "Parser",
    color: "#256EE2",
    shortDescription:
      "PARSER is an AI assistant that reads your existing resume and suggests you the corresponding conversion in the editor. Activate and upload your resume.",
    description:
      "AI based resume bot that can read and contextualize your resume and automatically parse it into the GetBoarded editor. It is developed with deep learning mechanisms and trained on huge data models. Parsing happens with your career history, skills, interests, and your personality. Platform uses predictive talent analytics to map your career roadmap and predict situations.",
    icon: require("@/assets/images/icons/unified-menu/parser.svg"),
    strokeIcon: require("@/assets/images/icons/unified-menu/stroke-icons/resume-parser.svg"),
    uni_color_icon: require("@/assets/images/icons/unified-menu/uni-color/parser.svg"),
    pricePerUser: "0.1",
    defaultCurreny: "eur",
    baseURL: "parser",
    baseURLDisabled: false,
    manager: "GetBoarded",
  },
  // {
  //   id: "abilities",
  //   title: "Abilities Lab",
  //   shortTitle: "Abilities Lab",
  //   color: "#F53855",
  //   shortDescription:
  //     "Abilities Lab for behavioural self-discovery and professional development.",
  //   description:
  //     "Abilities Lab is a competency mapping extension based on the Talent Genome. It allows users to self-assess and peer-assess professional behavioral attributes with a positively enforced feedback mechanism. Your own view of yourself is mapped to how other people see you and thus any gaps are identified and presented in a talent report. Being aware of your weaknesses and playing on your strengths will help you discover and tap into the potential you have, or in contrast, seek development support in areas which you realize you are lacking skill. As there is no negative manner of feedback collected, the data helps career counselors in universities or HRs in an organization to find proper fitment of people with roles and help plan career succession. A lot of further capabilities could be explored when you tap into the soft skill data with this extension. Recommendations of learning, planning for next level roles for succession, talent planning are a few examples.",
  //   icon: require("@/assets/images/icons/unified-menu/ability-lab.svg"),
  //   strokeIcon: require("@/assets/images/icons/unified-menu/stroke-icons/ability-lab.svg"),
  //   uni_color_icon: require("@/assets/images/icons/unified-menu/uni-color/ability-lab.svg"),
  //   pricePerUser: "0",
  //   defaultCurreny: "eur",
  //   baseURL: "editor/personality",
  //   baseURLDisabled: false,
  //   manager: "PeopleTree International",
  // },
  {
    id: "alex",
    title: "Library",
    shortTitle: "Library",
    color: "#F53855",
    shortDescription:
      "Library, an app with diverse learning resources for personalized competency development.",
    description:
      "Library uses learning recommendation engine that adds additional capabilities to your learning and development programs by personalizing based on individual needs and preferences. Additionally, the organization can hyper-personalize each training path for all participating individuals with journeys while leveraging on your internal training material.<br><br> Library initiates learning recommendations based on your current development needs assessed by you and your peers. Once initial benchmark is in place the algorithm evolves with recursive feedback mechanisms allowing the learner to customize their own learning habits and providing an effective and engaging experience using Microlearning content. This content in the training library also gets dynamically re-arranged and ranked with learner’s quality feedback improving the organizational knowledge base over time.<br><br> By using a hyper-personalization approach to one-to-one Distributed Development in a digital-first environment. Our adaptive learning models allow for efficient scale to whole of the organization while catering to each individual need.",
    icon: require("@/assets/images/icons/unified-menu/library.svg"),
    strokeIcon: require("@/assets/images/icons/unified-menu/stroke-icons/library.svg"),
    uni_color_icon: require("@/assets/images/icons/unified-menu/uni-color/library.svg"),
    pricePerUser: "30",
    defaultCurreny: "eur",
    baseURL: "/alex/home",
    baseURLDisabled: false,
    manager: "GetBoarded",
  },
  {
    id: "journey",
    title: "Journey",
    shortTitle: "Journey",
    color: "#0EB71F",
    shortDescription:
      "Personalized career paths for your organizational internal talent development.",
    description:
      "Personalized career paths for your organizational internal talent development. Create customized interactive journeys for your talent to work through gamified sequence of tasks.",
    icon: require("@/assets/images/icons/unified-menu/journey.svg"),
    strokeIcon: require("@/assets/images/icons/unified-menu/stroke-icons/journey.svg"),
    uni_color_icon: require("@/assets/images/icons/unified-menu/uni-color/journey.svg"),

    pricePerUser: "30",
    defaultCurreny: "eur",
    baseURL: "journey",
    baseURLDisabled: true,
    manager: "GetBoarded",
  },
  {
    id: "coach",
    title: "Mentor",
    shortTitle: "Mentor",
    color: "#9DCD5B",
    shortDescription:
      "'Mentor' is a coaching experience tracking app that manages the behavioral skill development progress by suggesting simple development actions, while getting feedback from advisors who validate visible change.",
    description:
      "Mentor helps you add skills to develop with pre-defined and custom progress measures. Your coaching relationship is defined in the app that allows both the trainee and the coach to continuously work using planned touchpoints. Other than your coaches, you can build an advisor circle of people who provide you feedback on the progress you make in selected skills. The development analytics shows you if you are decreasing on increasing the gap of your chosen skill with periodic inputs from your selected advisors.",
    icon: require("@/assets/images/icons/unified-menu/coach.svg"),
    strokeIcon: require("@/assets/images/icons/unified-menu/stroke-icons/coach.svg"),
    uni_color_icon: require("@/assets/images/icons/unified-menu/uni-color/coach.svg"),

    pricePerUser: "30",
    defaultCurreny: "eur",
    baseURL: "comm",
    baseURLDisabled: true,
    manager: "PeopleTreeGroup",
  },
  {
    id: "market",
    title: "Market",
    shortTitle: "Market",
    color: "#F79009    ",
    shortDescription:
      "Market App is a marketplace that we want to give to organizations to configure (setup with a GUI) so they can upload information about what their employees can purchase. The unique thing is that employees will be paying in terms of Points that they earn by doing small learning items",
    description:
      "The App is designed to provide organizations with an easy-to-use platform to offer merchandise. The products can be listed and tied to a point-based learning reward. People can earn points by completing small learning tasks or by engaging in other activities that support their professional development. These points can then be redeemed for a range of rewards that are uploaded by the organization. The app provides a simple and efficient way for organizations to incentivize and recognize people efforts towards skill-building and continuous learning.",
    icon: require("@/assets/images/icons/unified-menu/market.svg"),
    strokeIcon: require("@/assets/images/icons/unified-menu/stroke-icons/giftsshop.svg"),
    uni_color_icon: require("@/assets/images/icons/unified-menu/uni-color/market.svg"),
    pricePerUser: "",
    defaultCurreny: "",
    baseURL: "",
    baseURLDisabled: true,
    manager: "Getboarded",
  },
  {
    id: "getboarded",
    title: "Getboarded",
    shortTitle: "Getboarded",
    color: "#F2C046",
    shortDescription: "",
    description: "",
    icon: require("@/assets/images/icons/unified-menu/getboarded.svg"),
    strokeIcon: require("@/assets/images/icons/unified-menu/stroke-icons/getboarded.svg"),
    pricePerUser: "",
    defaultCurreny: "",
    baseURL: "",
    baseURLDisabled: true,
    manager: "Getboarded",
  },
];
