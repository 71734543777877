<template>
  <section
    class="abilitiy__container d-flex padding--x py-1 pt-md-3 pb-md-1"
    :class="isUserLoggedIn ? '' : 'grey-out'"
    id="self-assessment"
  >
    <div class="w-100 h-100">
      <!-- Self assessment  -->
      <b-row class="flex-column mt-1">
        <h2 class="common__heading">Assess your Abilities</h2>
        <h4 class="common__subheading">
          To update my skills as how I see myself.
        </h4>
      </b-row>

      <div class="common__card abilities">
        <div
          class="d-flex flex-column flex-md-row align-items-center justify-content-between pr-1 mb-1 pl-0"
        >
          <h4 class="common__subheading">
            Rank your abilities from best to least known
          </h4>
          <div v-if="isUserLoggedIn" class="d-flex justify-content-between">
            <!--- Reset All --->
            <b-col
              class="cursor-pointer"
              md="4"
              cols="1"
              @click="
                list2.length > 0 || list3.length > 0
                  ? (resetUserAbility = true)
                  : ''
              "
              variant="flat-success"
            >
              <img
                v-b-tooltip.hover.bottom="'Reset All'"
                class="icon-color"
                :class="
                  list2.length > 0 || list3.length
                    ? 'cursor-pointer'
                    : 'icon-color-opacity'
                "
                width="20px"
                src="@/assets/images/icons/reset_icon.svg"
                alt="Reset Icon"
              />
            </b-col>
            <!--- Only Reset Priority --->
            <b-col
              class="cursor-pointer"
              md="4"
              cols="1"
              @click.stop="
                Object.keys(prioritiesObj).length > 0
                  ? (resetUserPriority = true)
                  : ''
              "
              icon-pack="material-icons"
            >
              <img
                class="icon-color"
                v-b-tooltip.hover.bottom="'Only Reset Priority'"
                :class="
                  Object.keys(prioritiesObj).length > 0
                    ? 'cursor-pointer'
                    : 'icon-color-opacity'
                "
                width="20px"
                src="@/assets/images/icons/low_priority.svg"
                alt="Low Priority"
              />
            </b-col>
          </div>
        </div>

        <div>
          <b-row
            v-if="isUserLoggedIn"
            class="flex-column flex-md-row justify-content-between"
          >
            <b-col
              :md="list2.length === 6 && list3.length === 6 ? 4 : 6"
              cols="12"
              class="abilitiy-box p-1"
              :class="list2.length === 6 && list3.length === 6 ? 'justify-content-between':''"
            >
              <h5 v-if="list2 && list2.length === 6">
                Characteristics that
                <span style="color: #46b6c3">BEST</span> describe you
              </h5>

              <h5 class="px-1" v-else>
                Choose the top 6 strengths (abilities that you are
                <span style="color: #46b6c3">BEST</span> known for)
              </h5>

              <draggable
                v-model="list2"
                v-bind="dragOptions"
                :move="onMove"
                @start="isDragging = true"
                @end="isDragging = false"
                class="d-flex flex-column align-items-center justify-content-center position-relative"
                :class="list2.length !== 6 ? 'drag-box' : ''"
              >
                <div :class="list2.length !== 6 ? '' : 'd-none'">
                  Drag and drop below characteristics here
                </div>
                <draggable
                  class="w-100"
                  v-model="list2"
                  v-bind="dragOptions"
                  :move="onMove"
                  @start="isDragging = true"
                  @end="isDragging = false"
                >
                  <transition-group class="row" name="'no'">
                    <b-col
                      cols="12"
                      class="p-0"
                      v-for="(personality, index) in list2.slice(0, 6)"
                      :key="index + 'best'"
                      v-b-tooltip.hover.right="personality.title.description"
                    >
                      <div
                        @click.stop="savePriorities(personality.title)"
                        class="ability__detail my-50 p-50 d-flex align-items-center cursor-pointer ability--best"
                      >
                        <div class="ability__icon">
                          <img
                            class="ability__icon__img"
                            :src="personality.title.pngIcon"
                            :alt="personality.title.title"
                          />
                        </div>
                        <div
                          class="ability__name w-100 px-75 h5 font-weight-bold d-flex justify-content-between align-items-center"
                        >
                          <span>{{ personality.title.title }}</span>
                          <span class="d-none">{{ priorityNum }}</span>
                          <span
                            v-if="
                              Object.keys(prioritiesObj).length &&
                              prioritiesObj.hasOwnProperty(
                                personality.title.mapId
                              )
                            "
                            class="d-flex align-items-center justify-content-center ability__priority-number"
                          >
                            {{
                              getPriorityNumber(personality.title.mapId)
                            }}</span
                          >
                        </div>
                      </div>
                    </b-col>
                  </transition-group>
                </draggable>
              </draggable>
            </b-col>

            <b-col
              :md="list2.length === 6 && list3.length === 6 ? 4 : 6"
              cols="12"
              class="abilitiy-box p-1 my-1 my-md-0"
              :class="
                list2.length === 6 && list3.length === 6
                  ? 'abilitiy-box-middle justify-content-between'
                  : ''
              "
            >
              <h5 v-if="list3 && list3.length === 6">
                Abilities that you are
                <span style="color: #f79009">SOMEWHAT</span> known for
              </h5>

              <h5 class="px-1" v-else>
                Choose the top 6 strengths (abilities that you are
                <span style="color: #7367f0">SOMEWHAT</span> known for)
              </h5>

              <draggable
                v-model="list3"
                v-bind="dragOptions"
                :move="onMove"
                @start="isDragging = true"
                @end="isDragging = false"
                class="d-flex flex-column align-items-center justify-content-center"
                :class="list3.length !== 6 ? 'drag-box' : ''"
              >
                <div :class="list3.length !== 6 ? '' : 'd-none'">
                  Drag and drop below characteristics here
                </div>

                <draggable
                  class="w-100"
                  v-model="list3"
                  v-bind="dragOptions"
                  :move="onMove"
                  @start="isDragging = true"
                  @end="isDragging = false"
                >
                  <transition-group class="row" name="'no'">
                    <b-col
                      cols="12"
                      class="p-0"
                      v-for="(personality, index) in list3.slice(0, 6)"
                      :key="index + 'best'"
                      v-b-tooltip.hover.right="personality.title.description"
                    >
                      <div
                        @click.stop="savePriorities(personality.title)"
                        class="ability__detail my-50 p-50 d-flex align-items-center cursor-pointer ability--most"
                      >
                        <div class="ability__icon">
                          <img
                            class="ability__icon__img"
                            :src="personality.title.pngIcon"
                            :alt="personality.title.title"
                          />
                        </div>
                        <div
                          class="ability__name w-100 px-75 h5 font-weight-bold d-flex justify-content-between align-items-center"
                        >
                          <span>{{ personality.title.title }}</span>
                          <span class="d-none">{{ priorityNum }}</span>
                          <span
                            v-if="
                              Object.keys(prioritiesObj).length &&
                              prioritiesObj.hasOwnProperty(
                                personality.title.mapId
                              )
                            "
                            class="d-flex align-items-center justify-content-center ability__priority-number"
                          >
                            {{
                              getPriorityNumber(personality.title.mapId)
                            }}</span
                          >
                        </div>
                      </div>
                    </b-col>
                  </transition-group>
                </draggable>
              </draggable>
            </b-col>

            <b-col
              class="abilitiy-box p-1 my-1 my-md-0"
              md="4"
              cols="12"
              v-if="
                list2.length === 6 && list3.length === 6 && list4.length === 6
              "
              :class="list2.length === 6 && list3.length === 6 && list4.length === 6 ? 'justify-content-between':''"
            >
              <h5>
                Abilities that you are
                <span style="color: #46b6c3">LEAST</span> known for
              </h5>

              <draggable
                v-model="list4"
                v-bind="dragOptions"
                :move="onMove"
                @start="isDragging = true"
                @end="isDragging = false"
              >
                <transition-group class="row" name="'no'">
                  <b-col
                    cols="12"
                    class="p-0"
                    v-for="(personality, index) in list4"
                    :key="index + 'avoid-duplicacy'"
                    v-b-tooltip.hover.right="personality.title.description"
                  >
                    <div
                      @click.stop="savePriorities(personality.title)"
                      class="ability__detail my-50 p-50 d-flex align-items-center cursor-pointer"
                    >
                      <div class="ability__icon">
                        <img
                          class="ability__icon__img"
                          :src="personality.title.pngIcon"
                          :alt="personality.title.title"
                        />
                      </div>
                      <div
                        class="ability__name w-100 px-75 h5 font-weight-bold d-flex justify-content-between align-items-center"
                      >
                        <span>{{ personality.title.title }}</span>
                        <span class="d-none">{{ priorityNum }}</span>
                        <span
                          v-if="
                            Object.keys(prioritiesObj).length &&
                            prioritiesObj.hasOwnProperty(
                              personality.title.mapId
                            )
                          "
                          class="d-flex align-items-center justify-content-center ability__priority-number"
                        >
                          {{ getPriorityNumber(personality.title.mapId) }}</span
                        >
                      </div>
                    </div>
                  </b-col>
                </transition-group>
              </draggable>
            </b-col>
          </b-row>
          <b-row
            class="p-0 mt-md-1"
            v-if="list2.length < 6 || list3.length < 6"
          >
            <draggable
              v-model="list"
              v-bind="dragOptions"
              :move="onMove"
              @start="isDragging = true"
              @end="isDragging = false"
            >
              <transition-group class="row" name="'no'">
                <b-col
                  md="4"
                  class="p-0"
                  v-show="list.length > 0"
                  v-for="(personality, index) in list.slice(0, 18)"
                  :key="index + 'avoid-duplicacy'"
                >
                  <b-col
                    v-b-tooltip.hover.right="personality.title.description"
                    class="pd-md"
                    :class="
                      index % 3 === 0 || (index + 1) % 3 === 0 ? 'p-0' : ''
                    "
                  >
                    <div
                      class="ability__detail my-50 p-50 d-flex align-items-center cursor-pointer"
                    >
                      <div class="ability__icon">
                        <img
                          class="ability__icon__img"
                          :src="personality.title.pngIcon"
                          :alt="personality.title.title"
                        />
                      </div>
                      <div
                        class="ability__name w-100 px-75 h5 font-weight-bold d-flex justify-content-between align-items-center"
                      >
                        {{ personality.title.title }}
                      </div>
                    </div>
                  </b-col>
                </b-col>
              </transition-group>
            </draggable>
          </b-row>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BCol, BRow, VBTooltip } from "bootstrap-vue";
import personalityTraits from "@/assets/data/personalityTraits.js";
import designMixin from "@/mixins/designMixin";
import notificationMixin from "@/mixins/notificationMixin.js";
import draggable from "vuedraggable";
export default {
  components: {
    BRow,
    BCol,
    draggable,
  },
  mixins: [designMixin, notificationMixin],
  data() {
    return {
      personalityTraits,
      shuffledPersonalityData: [],
      abilitiesavedData: [],
      selectedPersonalityData: [],
      abilitiesArray: [],
      prioritiesObj: {},
      priorityNum: 1,
      list: [],
      list2: [],
      list3: [],
      list4: [],
      editable: true,
      isDragging: false,
      delayedDragging: false,
      apiCall: false,
      selectedabilities: [],
      resetUserAbility: false,
      resetUserPriority: false,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  computed: {
    isUserLoggedIn() {
      return this.$store.state.auth.isUserLoggedIn;
    },
    userData() {
      return this.$store.state.user.userData;
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost",
      };
    },
    selectedPersonalities() {
      return this.$store.state.user.selectedPersonalities;
    },
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
    resetUserAbility(newVal) {
      if (newVal === true) {
        this.resetUserAbility = false;
        this.selectedPersonalityData = [];
        this.selectedabilities = [];
        this.list2 = [];
        this.list3 = [];
        this.list4 = [];
        this.apiCall = false;
        this.priorityNum = 1;
        this.prioritiesObj = {};
        this.$store.commit("user/SET_USER_PRIORITIES_OBJ", this.prioritiesObj);
        this.shufflePersonalityData();
        if (this.selectedabilities.length === 0) {
          const payload = {
            userId: this.userData._id,
            abilities: this.selectedabilities,
          };
          const priorityPayload = {
            userId: this.userData._id,
            priorities: this.prioritiesObj,
          };
          this.$store.dispatch("user/saveUserPriority", priorityPayload);
          this.$store
            .dispatch("user/saveUserAbility", payload)
            .then(() => {
              let notificationDetailSec = {
                message: this.messages.resetAbilitiy.text(),
                variant: "success",
              };
              this.showToast(notificationDetailSec);
            })
            .catch(() => {
              // console.log(err);
              let notificationDetail = {
                message: this.messages.error.unknown_error,
                variant: "danger",
              };
              this.showToast(notificationDetail);
            });
        }
      }
    },
    resetUserPriority(newVal) {
      if (newVal === true) {
        this.priorityNum = 1;
        this.prioritiesObj = {};
        this.$store.commit("user/SET_USER_PRIORITIES_OBJ", this.prioritiesObj);
        const payload = {
          userId: this.userData._id,
          priorities: this.prioritiesObj,
        };
        this.$store.dispatch("user/saveUserPriority", payload);
        let notificationDetailSec = {
          message: this.messages.resetPriority.text(),
          variant: "success",
        };
        this.showToast(notificationDetailSec);
        return (this.resetUserPriority = false);
      }
    },
    userData() {
      this.getUserAbilities();
    },
    list2(newVal, oldVal) {
      oldVal.length > 0 ? (this.apiCall = true) : "";
      if (newVal && (this.list3.length === 6) & (this.list4.length === 6)) {
        this.selectedabilities = [];
        this.selectedabilities.push(...newVal);
        this.selectedabilities.push(...this.list3);
        this.selectedabilities.push(...this.list4);
      }
      if (newVal && newVal.length > 6) {
        if (this.list4 && this.list4.length > 0) {
          this.list = [];
          this.list.push(...this.list4);
          this.list4 = [];
        }
        const extraSkill = newVal.splice(6, 1);
        return this.list.push(extraSkill[0]);
      }
    },
    list3(newVal, oldVal) {
      oldVal.length > 0 ? (this.apiCall = true) : "";
      if (newVal && (this.list3.length === 6) & (this.list4.length === 6)) {
        this.selectedabilities = [];
        this.selectedabilities.push(...newVal);
        this.selectedabilities.push(...this.list2);
        this.selectedabilities.push(...this.list4);
      }
      if (newVal && newVal.length > 6) {
        if (this.list4 && this.list4.length > 0) {
          this.list = [];
          this.list.push(...this.list4);
          this.list4 = [];
        }
        const extraSkill = newVal.splice(6, 1);
        return this.list.push(extraSkill[0]);
      }
    },
    list(newVal) {
      if (newVal && newVal.length === 6) {
        if (this.list4.length === 0 || this.list4.length === 7) {
          this.list4 = [];
          this.list4.push(...newVal);
        }
      }
    },
    list4(newVal) {
      if (newVal && newVal.length === 6) {
        this.apiCall = true;
        this.selectedabilities = [];
        this.selectedabilities.push(...this.list2);
        this.selectedabilities.push(...this.list3);
        this.selectedabilities.push(...newVal);
        this.list = [];
        this.list.push(...newVal);
      } else if (newVal && newVal.length === 7) {
        // newVal = [];
        this.list = [];
        this.list.push(...newVal);
      }
    },
    selectedabilities(newVal) {
      if (newVal.length === 18 && this.apiCall) {
        let selectedabilitiyOrder = [];
        this.apiCall = false;
        newVal.forEach((abilitiy) => {
          selectedabilitiyOrder.push(abilitiy.order);
        });
        const payload = {
          userId: this.userData._id,
          abilities: selectedabilitiyOrder,
        };
        this.$store
          .dispatch("user/saveUserAbility", payload)
          .then(() => {
            if (
              this.selectedPersonalityData &&
              this.selectedPersonalityData.length === 18
            ) {
              return;
            } else {
              this.selectedPersonalityData = [];
              let notificationDetail = {
                title: this.messages.abilitySelection.completion.title(),
                message: this.messages.abilitySelection.completion.text(),
                variant: "info",
              };
              this.showToast(notificationDetail);
              let notificationDetailSec = {
                title: this.messages.abilitySelection.wantToDevelop.title(),
                message: this.messages.abilitySelection.wantToDevelop.text,
                variant: "warning",
              };
              this.showToast(notificationDetailSec);
              this.selectedPersonalityData.push(...newVal);
              this.$store.commit("user/SET_IS_USER_ABILITIES", true);
            }
          })
          .catch(() => {
            // console.log(err);
            let notificationDetail = {
              message: this.messages.error.unknown_error,
              variant: "danger",
            };
            this.showToast(notificationDetail);
          });
      }
    },
    selectedPersonalityData(newVal) {
      if (newVal && newVal.length === 18) {
        this.list4 = [];
        this.list2 = [];
        this.list3 = [];
        for (let i = 0; i < newVal.length; i++) {
          if (i < 6) {
            this.list2.push(newVal[i]);
          } else if (i > 5 && i < 12) {
            this.list3.push(newVal[i]);
          } else {
            this.list4.push(newVal[i]);
          }
        }
      }
    },
  },
  methods: {
    onMove({ relatedContext, draggedContext }) {
      if (this.isUserLoggedIn) {
        const relatedElement = relatedContext.element;
        const draggedElement = draggedContext.element;
        return (
          (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
        );
      } else {
        let notificationDetail = {
          message: this.messages.not_loggedIn.text,
          variant: "danger",
        };
        this.showToast(notificationDetail);
      }
    },
    getUserAbilities() {
      // priorities also existed in the same
      if (this.isUserLoggedIn && this.userData && this.userData._id) {
        this.$store.commit("modals/SET_UNIVERSAL_LOADING", true);
        let abilities = [];
        this.$store
          .dispatch("user/getUserAbility", this.userData._id)
          .then((res) => {
            this.$store.commit("user/SET_IS_USER_ABILITIES", true);
            abilities = res.abilities;
            if (res.priorities) {
              this.priorityNum = Object.keys(res.priorities).length + 1;
              this.prioritiesObj = res.priorities;
              this.$store.commit(
                "user/SET_USER_PRIORITIES_OBJ",
                this.prioritiesObj
              );
            } else {
              this.priorityNum = 1;
            }
            this.shuffledPersonalityData = [];

            if (abilities && abilities.length === 18) {
              for (let i = 0; i < abilities.length; i++) {
                let personalityObj = this.list.find((p) => {
                  return p.order === abilities[i];
                });
                this.getPriorityNumber(abilities[i]);
                this.selectedPersonalityData.push(personalityObj);
              }
            }
          })
          .catch(() => {
            // console.log(err);
          })
          .finally(() => {
            !abilities.length ? this.shufflePersonalityData() : "";
            this.$store.commit("modals/SET_UNIVERSAL_LOADING", false);
          });
      } else {
        this.shufflePersonalityData();
      }
    },
    getPriorityNumber(id) {
      let priorityCount = this.prioritiesObj[id];
      return priorityCount;
    },
    shufflePersonalityData() {
      let data = personalityTraits.map((title, index) => {
        return { title, order: index + 1, fixed: false };
      });
      return (this.list = data.sort(() => Math.random() - 0.5));
    },
    savePriorities(personality) {
      if (this.selectedPersonalityData.length === 18) {
        if (
          Object.prototype.hasOwnProperty.call(
            this.prioritiesObj,
            personality.mapId
          )
        ) {
          let notificationDetail = {
            title: this.messages.priorityAlreadyExist.title,
            message: this.messages.priorityAlreadyExist.text({
              name: personality.title,
              number: this.prioritiesObj[personality.mapId],
            }),
            variant: "danger",
          };
          this.showToast(notificationDetail);
          return;
        } else {
          this.prioritiesObj[personality.mapId] = this.priorityNum;
          this.$store.commit(
            "user/SET_USER_PRIORITIES_OBJ",
            this.prioritiesObj
          );
          const payload = {
            userId: this.userData._id,
            priorities: this.prioritiesObj,
          };
          this.$store.dispatch("user/saveUserPriority", payload);
          this.priorityNum++;
        }
      }
    },
  },
  created() {
    this.shufflePersonalityData();
    setTimeout(this.anchorHashCheck(), 3500);
    this.getUserAbilities();
  },
};
</script>

<style lang="scss" scoped>
.abilitiy__container {
  .abilitiy-box {
    background: #f9fafb;
    border: 1px dashed #98a2b3;
    border-radius: 6px;
    padding: 1em;
    font-size: 1.25em;
    display: flex;
    flex-direction: column;
    &-middle {
      border-left: none;
      border-right: none;
    }
  }

  .icon-color {
    filter: invert(40%) sepia(88%) saturate(3880%) hue-rotate(325deg)
      brightness(100%) contrast(94%);

    &-opacity {
      opacity: 0.5;
    }
  }
  .drag-box {
    background: #5e587312;
    border: 2px dashed #eaecf0;
    border-radius: 6px;
    padding: 1em;
    left: 0;
    top: 0;
  }

  .col-md-6 {
    flex: 0 0 47.5%;
    max-width: 47.5%;
  }
}

h5 {
  font-size: 0.95em;
}

@media only screen and (max-width: 768px) {
  .abilitiy__container {
    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .pd-md {
      padding: 0;
    }

    .abilitiy-box {
      &-middle {
        border: 1px dashed #98a2b3;
      }
    }
  }
}
</style>
