import {
  Linkedin,
  Twitter,
  Facebook,
  Instagram,
  Medium,
  // Github,
} from "@/assets/images/icons/social-icons/index.js";

let socialHandles = [
  {
    icon: Linkedin,
    iconName: 'Linkedin',
    link: "https://www.linkedin.com/company/getboarded/mycompany/",
  },
  {
    icon: Twitter,
    iconName: 'Twitter',
    link: "https://twitter.com/gtbrdd",
  },
  {
    icon: Facebook,
    iconName: 'Facebook',
    link: "https://www.facebook.com/Getboarded",
  },
  {
    icon: Instagram,
    iconName: 'Instagram',
    link: "https://www.instagram.com/getboarded/",
  },
  {
    icon: Medium,
    iconName: 'Medium',
    link: "https://medium.com/@gtbrdd",
  },
  // {
  //   icon: Github,
  //   link: "/",
  // },
];

export default socialHandles;
