<template>
  <section
    class="predictnplans__container padding--x"
    :class="isUserLoggedIn ? '' : 'grey-out'"
  >
    <b-row class="justify-content-between">
      <b-col cols="12" sm="6" class="pr-md-1 pl-md-0">
        <div class="common__card">
          <div
            class="common__card__icon d-flex align-items-center justify-content-center"
          >
            <img
              src="@/assets/images/icons/predictAndPlan/predict.svg"
              class="p-50 p-md-1 h-100 w-100"
              alt="Predict Situations"
            />
          </div>

          <b-row class="flex-column my-50 my-lg-2">
            <h2 class="common__heading">Predict Situations</h2>
            <h4 class="common__subheading">
              Based on your validated abilities, predict probability of success
              with various career situations
            </h4>
          </b-row>

          <button
            v-if="isTalentReportGenerated"
            class="common__btn d-flex align-items-center"
            @mouseenter="isHoveredSituation = true"
            @mouseleave="isHoveredSituation = false"
            @click="getSituationsReport"
          >
            <img src="@/assets/images/icons/unlock.svg" alt="Unlock" />
            <div class="common__btn__text mx-50">
              Unlock {{ isHoveredSituation ? "Probability Matching" : "" }}
            </div>
          </button>

          <button
            v-else
            class="common__btn d-flex align-items-center justify-content-center"
          >
            <img src="@/assets/images/icons/lock.svg" alt="Lock" />
            <div class="common__btn__text mx-50">Locked</div>
          </button>
        </div>
      </b-col>

      <b-col cols="12" sm="6" class="pl-md-1 pr-md-0">
        <div class="common__card common__card-2">
          <div
            class="common__card__icon d-flex align-items-center justify-content-center"
          >
            <img
              src="@/assets/images/icons/predictAndPlan/career.svg"
              class="p-50 p-md-1 h-100 w-100"
              alt="Career Plans"
            />
          </div>

          <b-row class="flex-column my-50 my-lg-2">
            <h2 class="common__heading">Career Plans</h2>
            <h4 class="common__subheading">
              Generated career guides to rethink and develop abilities that are
              needed to be successful in your chosen path.
            </h4>
          </b-row>

          <button
            v-if="isTalentReportGenerated"
            class="common__btn d-flex align-items-center"
            @mouseenter="isHoveredCareerPlan = true"
            @mouseleave="isHoveredCareerPlan = false"
            @click="getPlanReport"
          >
            <img src="@/assets/images/icons/unlock.svg" alt="Unlock" />
            <div class="common__btn__text mx-50">
              Unlock {{ isHoveredCareerPlan ? "Career Plan Creation" : "" }}
            </div>
          </button>

          <button
            v-else
            class="common__btn d-flex align-items-center justify-content-center"
          >
            <img src="@/assets/images/icons/lock.svg" alt="Lock" />
            <div class="common__btn__text mx-50">Locked</div>
          </button>
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BCol, BRow } from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
      isHoveredCareerPlan: false,
      isHoveredSituation: false,
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.userData;
    },
    isTalentReportGenerated() {
      return this.$store.state.user.isTalentReportGenerated ;
    },
    isUserLoggedIn() {
      return this.$store.state.auth.isUserLoggedIn;
    },
  },
  methods: {
    getPlanReport() {
      this.$store
        .dispatch("user/getPlanReport", this.userData._id)
        .then((res) => {
          if (res?.data?.url) {
            this.$store.commit("modals/OPEN_REPORT_POPUP", true);
            this.$store.commit("user/SET_REPORT_URL", res.data.url);
          }
        })
        .catch(() => {
          return;
        });
    },
    getSituationsReport() {
      this.$store
        .dispatch("user/getSituationsReport", this.userData._id)
        .then((res) => {
          if (res?.data?.url) {
            this.$store.commit("modals/OPEN_REPORT_POPUP", true);
            this.$store.commit("user/SET_REPORT_URL", res.data.url);
          }
        })
        .catch(() => {
          return;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.predictnplans__container {
  .common {
    &__heading {
      font-size: 1.75em;
    }
    &__subheading {
      font-weight: 400;
    }
    &__btn {
      min-width: 10px;
      &:hover {
        min-width: 10px;
      }
    }
    &__card {
      &__icon {
        background: #f63d68;
        box-shadow: 0px 14px 14px rgba(246, 61, 104, 0.2);
        width: 5em;
        height: 5em;
        border-radius: 10px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .predictnplans__container {
    .common {
      &__heading {
        font-size: 1.5em;
      }

      &__card {
        &__icon {
          width: 4em;
          height: 4em;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .predictnplans__container {
    .common {
      &__heading {
        font-size: 1.5em;
      }
      &__card {
        // height: 85%;
        &__icon {
          width: 3.5em;
          height: 3.5em;
        }
      }
    }
  }
}
@media only screen and (max-width: 660px) {
  .predictnplans__container {
    .common {
      &__heading {
        font-size: 1.25em;
      }
      &__card {
        // &-2 {
        //   margin-top: 0;
        // }
        &__icon {
          width: 3em;
          height: 3em;
        }
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .predictnplans__container {
    .common {
      &__card {
        height: auto;
        &-2 {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
