<template>
  <div class="home">
    <Loading v-if="isUniversalLoading" />
    <Navbar />
    <Abilities  />
    <InviteAdvisor />
    <PredictNPlans />
    <Footer />

    <EditorModal v-if="editorPopup" />
    <SkillReportModal v-if="talentReportPopup && reportUrl" />
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import Navbar from "@/components/Navbar.vue";
import Abilities from "@/components/landing/Abilities.vue";
import InviteAdvisor from "@/components/landing/InviteAdvisor.vue";
import PredictNPlans from "@/components/landing/PredictNPlans.vue";
import Footer from "@/components/Footer.vue";
//modals
import EditorModal from "@/modals/EditorModal.vue";
import SkillReportModal from "@/modals/SkillReportModal.vue";

export default {
  components: {
    Loading,
    Navbar,
    Abilities,
    InviteAdvisor,
    PredictNPlans,
    Footer,
    //modals
    EditorModal,
    SkillReportModal,
  },
  computed: {
    editorPopup() {
      return this.$store.state.modals.editorPopup;
    },
    talentReportPopup() {
      return this.$store.state.modals.talentReportPopup;
    },
    reportUrl() {
      return this.$store.state.user.reportUrl;
    },
    isUniversalLoading() {
      return this.$store.state.modals.isUniversalLoading;
    },
  },
  created() {
    const orgId = this.$route?.params?.orgId;
    if (orgId) {
      this.$store.commit("user/SET_USER_ACTIVE_ORG_ID", orgId);
    } else {
      this.$router.push("/error-404");
    }

    const token = localStorage.getItem("accessToken");
    if (token) {
      this.$store
        .dispatch("user/fetchUserDetailsByToken", token)
        .then((res) => {
          localStorage.setItem("user", JSON.stringify(res.data));
          this.$store.commit("user/SET_USER", res.data);
        });
    }
  },
  mounted() {
    this.scrollToIdFromUrl();
  },
  methods: {
    scrollToIdFromUrl() {
      const hash = window.location.hash;
      if (hash) {
        const targetId = hash.substring(1);
        const targetElement = this.$refs[targetId];
        if (targetElement) {
          targetElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
            offsetTop: targetElement.offsetTop,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";
.home {
  background: #ffffff;
}
</style>
